import { Controller } from "stimulus";
import Tribute from "tributejs";
import Trix from "trix";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.editor = this.fieldTarget.editor;
    this.initializeTribute();
    this.spinner = document.getElementById('loading-spinner');
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget);
    this.leader.detach(this.fieldTarget);
    this.askai.detach(this.fieldTarget);
  }

  initializeTribute() {
    this.tribute = new Tribute({
      trigger: '@',
      allowSpaces: false,
      lookup: 'title',
      values: this.fetchUsers.bind(this),
    });

    this.leader = new Tribute({
      trigger: '#',
      allowSpaces: false,
      lookup: 'elements',
      values: this.fetchLead.bind(this),
    });

    this.askai = new Tribute({
      trigger: '^',
      allowSpaces: false,
      lookup: 'elements',
      values: this.fetchResponse.bind(this),
    });

    // Override default behavior to prevent dropdown
    this.askai.showMenu = () => {
      // Prevents the menu from showing
    };

    this.askai.selectItem = (item) => {
      const { elements } = item.original;
      this._insertDirectly(elements);
    };

    this.attachTributes();
  }

  attachTributes() {
    [this.tribute, this.leader].forEach(tribute => {
      tribute.attach(this.fieldTarget);
      tribute.range.pasteHtml = this._pasteHtml.bind(this);
    });

    // Attach `askai` but use the custom behavior for direct insertion
    this.askai.attach(this.fieldTarget);

    // Ensure the custom pasteHtml method is used
    this.askai.range.pasteHtml = this._pasteHtml.bind(this);

    this.fieldTarget.addEventListener("tribute-replaced", this.replaced.bind(this));
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => {
        console.error('Error fetching users:', error);
        callback([]);
      });
  }

  fetchResponse(text, callback) {
    this._showSpinner(); // Show spinner before the fetch request
    const path = window.location.pathname;
    fetch(`${path}/propose_next_message.json`)
      .then(response => response.json())
      .then(response => {
        this._hideSpinner(); // Hide spinner when data is received
        callback(response);
      })
      .catch(error => {
        console.error('Error fetching response:', error);
        this._hideSpinner(); // Hide spinner in case of error
        callback([]);
      });
  }

  fetchLead(text, callback) {
    const path = window.location.pathname;
    fetch(`${path}.json?query=${text}`)
      .then(response => response.json())
      .then(lead => callback(lead))
      .catch(error => {
        console.error('Error fetching lead:', error);
        callback([]);
      });
  }

  replaced(e) {
    const mention = e.detail.item.original;
    this._insertDirectly(mention.content);
  }

  _pasteHtml(html, startPos, endPos) {
    const position = this.editor.getPosition();
    this.editor.setSelectedRange([position - endPos, position]);
    this.editor.deleteInDirection("backward");
  }

  _insertDirectly(html) {
    // Insert HTML content into the Trix editor
    this.editor.insertHTML(html);
  }

  _showSpinner() {
    if (this.spinner) {
      this.spinner.style.display = 'block';
    }
  }

  _hideSpinner() {
    if (this.spinner) {
      this.spinner.style.display = 'none';
    }
  }
}
