import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    this.sortable = 
    Sortable.create(
    	this.element, {
    	group: {
		    name: 'kontakt',
		    put: ['lost', 'kontaktlos', 'kontakt', 'deal', 'offen','interessiert'],
		    pull: true
		  },
    	onEnd: this.end.bind(this)
    	
    })
    this.sortable = 
    Sortable.create(
    this.element, {
	  group: {
	    name: 'offen',
	    put: ['lost', 'kontaktlos', 'kontakt', 'deal', 'offen','interessiert'],
	    pull: true
	  },
	  onEnd: this.end.bind(this)
	})
	this.sortable = 
    Sortable.create(
    this.element, {
	  group: {
	    name: 'interessiert',
	    put: ['lost', 'kontaktlos', 'kontakt', 'deal', 'offen','interessiert'],
	    pull: true
	  },
	  onEnd: this.end.bind(this)
	})
	this.sortable = 
    Sortable.create(
    this.element, {
	  group: {
	    name: 'deal',
	    put: ['lost', 'kontaktlos', 'kontakt', 'deal', 'offen','interessiert'],
	    pull: true
	  },
	  onEnd: this.end.bind(this)
	})
	this.sortable = 
	Sortable.create(
    this.element, {
	  group: {
	    name: 'kontaktlos',
	    put: ['lost', 'kontaktlos', 'kontakt', 'deal', 'offen','interessiert'],
	    pull: true
	  },
	  onEnd: this.end.bind(this)
	})
	this.sortable = 
	Sortable.create(
    this.element, {
	  group: {
	    name: 'lost',
	    put: ['lost', 'kontaktlos', 'kontakt', 'deal', 'offen','interessiert'],
	    pull: true
	  },
	  onEnd: this.end.bind(this)
	})

  }

  end(event){
  	let id = event.item.dataset.id
	console.log(event)


	let data = new FormData()
	data.append("last_interest_state", event.to.id)


	Rails.ajax({
		url:this.data.get("url").replace(":id", id),
		type:"PATCH",
		data: data
		,success: window.location.reload()
	})
	}
}

