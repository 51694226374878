// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("jquery")
require("@rails/activestorage").start()
require("channels")
require("trix")
require("chartkick")
require("chart.js")
require("select2/dist/css/select2")


import 'bootstrap';

import Chart from 'chart.js';
import Chartkick from 'chartkick';
import ahoy from "ahoy.js";
import 'cocoon-js';




window.Chartkick = Chartkick
Chartkick.addAdapter(Chart)
Chartkick.options = {
  library: {animation: {easing: 'easeOutQuart'}},
}

ahoy.configure({
  urlPrefix: "",
  visitsUrl: "/ahoy/visits",
  eventsUrl: "/ahoy/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: false,
  cookieDomain: null,
  headers: {},
  visitParams: {},
  withCredentials: false
});

ahoy.trackAll();

window.setTimeout(function() {
$(".alert").fadeTo(1000, 0).slideUp(1000, function(){
$(this).remove();
});
}, 5000);





/*
document.addEventListener("turbolinks:load", () =>{

if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
    Chartkick.eachChart(function (chart) {
      chart.updateData(null)
      chart.setOptions({...chart.getOptions(), ...{library: {animation: false}}})
    })
  }

  });


*/


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
    .then(function(reg) {
      console.log('[Page] Service worker registered!');
    });
}
document.addEventListener("turbolinks:load", () =>{

$('#nav-tab a').on('click', function (e) {
  e.preventDefault()
  $(this).tab('show')
})
});

          document.addEventListener("turbolinks:load", () =>{
         
          $('#myModal').modal('show');

 

          });
document.addEventListener("turbolinks:load", () =>{

 $('[data-tip="tooltip"]').tooltip();

  });

document.addEventListener("turbolinks:load", () =>{
    $('.select2').select2({
     theme: 'bootstrap-5'
    });
  });

document.addEventListener("turbolinks:load", () =>{



$(".panel-heading").mouseenter(function () {
        $(".panel-collapse").collapse("show");
    });
 $(".panel-collapse").mouseleave(function(){
       $(".panel-collapse").collapse("hide");
});

  });



document.addEventListener("turbolinks:load", () =>{
 
   
    
    $('#addresses').on('cocoon:before-insert', function(e, el_to_add) {
      return el_to_add.fadeIn(1000);
    });
    $('#addresses').on('cocoon:after-insert', function(e, added_el) {
      added_el.effect('highlight', {}, 500);
      return recount();
    });
    $('#addresses').on('cocoon:before-remove', function(e, el_to_remove) {
      $(this).data('remove-timeout', 1000);
      return el_to_remove.fadeOut(1000);
    });
    return $('#addresses').on('cocoon:after-remove', function(e, removed_el) {
      return recount();
    });
  });



document.addEventListener("turbolinks:load", () =>{
  

flatpickr(".selector", {
locale: {
        firstDayOfWeek: 1
    },
defaultDate: true,
altFormat: "j. F Y, H:i",
enableTime: true,
dateFormat: "d.m.Y H:i",
time_24hr: true,
minTime: "5:00",
maxTime: "23:00",
weekNumbers: true
});
  });

require("@rails/actiontext")
import "controllers"
