import { Controller } from "stimulus"
import Sortable from "sortablejs"

import Rails from "@rails/ujs"

export default class extends Controller {
	static targets = [ "dateday" ]
  connect() {
  	

	//console.log(this.data.get("datenew"))
	//console.log(this.datedayTarget)
    
    this.sortable = 
    Sortable.create(
    	this.element, {
    	
    	group: {
		    name: 'calenderdate',
		   // put: ['lost', 'kontaktlos', 'kontakt', 'deal', 'offen','interessiert'],
		   pull: true,
		   put:true
		   
		  },
    	
    	onEnd: this.end.bind(this)
    	
    })
    

  }


  end(event){
	
  	
  	let id = event.item.dataset.id

  	let date = event.to.dataset.calendarDatenew
  	console.log("Date: ",date)
  	
  	

	console.log(event)


	let data = new FormData()
	//data.append("next_contact", event.to.id)
	data.append("date", date)
	

	Rails.ajax({
		url: this.data.get("url").replace(":id", id),
		type:"PATCH",
		data: data,
		success: function(data) { window.location.reload() },
		error: function(data) { alert("Fehler") }


	})
	}

}
